import React from 'react';
import circle from '../../assets/images/circle-icon.svg';
import profile1 from '../../assets/images/profile-icon-1.svg';
import profile2 from '../../assets/images/profile-icon-2.svg';
import profile3 from '../../assets/images/profile-icon-3.svg';

const Case = () => {
  return (
    <section className="w-full h-full 2xs:px-[20px] 2xs:py-10 xs:px-[24px] xs:py-16 sm:px-[24px] sm:py-16 md:px-[200px] md:py-[100px] tb:flex tb:flex-col tb:items-center tb:justify-center tb:px-[200px] tb:py-[100px] lg:px-[180px] lg:py-[100px] xl:px-[280px] bg-[#D6E1F2] text-center">
      <div>
        <p className="2xs:hidden xs:hidden sm:hidden md:text-[32px] tb:text-[32px] lg:text-[32px] font-bold">
          처음 겪을 떨어짐, 두려워할 필요 없어요
        </p>
        <p className="2xs:text-[24px] xs:text-[24px] sm:text-[24px] md:hidden tb:hidden lg:hidden font-bold">
          처음 겪을 떨어짐,
          <br />
          두려워할 필요 없어요
        </p>
        <p className="2xs:hidden xs:text-[13px] sm:text-[13px] md:text-[18px] tb:text-[18px] lg:text-[18px] mt-[16px]">
          상실감을 설레임으로 바꿔줄 달의 우체통과 함께한다면요.
        </p>
        <p className="2xs:text-[12px] xs:hidden sm:hidden md:hidden tb:hidden lg:hidden mt-[10px]">
          상실감을 설레임으로 바꿔줄
          <br />
          달의 우체통과 함께한다면요.
        </p>
      </div>
      <div className="relative 2xs:mt-[36px] mt-[60px] flex flex-col lg:flex-row 2xs:gap-5 xs:gap-7 sm:gap-7 md:gap-7 tb:gap-8 lg:gap-8 sm:px-[4px] md:px-[12px] justify-around tb:justify-center">
        <div className="profile1 flex flex-col w-full h-[168px] 2xs:px-3 xs:px-4 sm:px-4 py-1 md:h-[200px] md:px-[28px] md:py-[28px] md:rounded-[30px] rounded-2xl 2xs:h-[126px] tb:w-[400px] tb:h-[216px] tb:p-[26px] tb:rounded-[30px] lg:w-[378px] lg:h-[216px] lg:p-[26px] lg:rounded-[30px] text-left bg-white">
          <div className="relative flex flex-col">
            <div className="2xs:mt-[16px] xs:mt-[16px] sm:mt-[16px] 2xs:text-[12px] xs:text-[14px] sm:text-[14px] md:text-[18px] md:w-full md:max-w-[320px] md:max-h-28 tb:text-[18px] tb:w-full tb:min-w-[336px] tb:max-h-28 lg:text-[18px] lg:w-full lg:min-w-[336px] lg:max-h-28 flex-shrink-0 leading-[150%]">
              <p className="font-bold text-[#3F29C1]">
                매일을 버티는 원동력이 되어주었어요.
              </p>
              <p>
                외롭다가도 매일 편지가 올 시간이면 설레는 마음으로 기다리게
                되더라구요.
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="2xs:mt-[24px] xs:mt-[54px] sm:mt-[54px] md:mt-[54px] tb:mt-[60px] lg:mt-[60px] text-[14px] 2xs:text-[11.6px] sm:text-[14px] tb:text-[18px] lg:text-[18px] text-[#888790] flex flex-row items-center gap-2">
              <p>이**</p>
              <p>
                <img
                  src={circle}
                  className="2xs:w-[3px] 2xs:h-[3px] xs:w-[3px] xs:h-[3px] sm:w-[3px] sm:h-[3px]"
                  alt=""
                />
              </p>
              <p>2개월 차 곰신</p>
            </div>
            <div className="flex items-end">
              <img
                className="2xs:w-10 2xs:h-8 xs:w-12 xs:h-10 sm:w-12 sm:h-10 tb:w-[36px] tb:h-[48px] lg:w-[36px] lg:h-[48px]"
                src={profile1}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="profile2 flex flex-col w-full h-[168px] 2xs:px-3 xs:px-4 sm:px-4 py-1 rounded-2xl 2xs:h-[126px] tb:w-[400px] tb:h-[216px] tb:p-[26px] tb:rounded-[30px] lg:w-[378px] lg:h-[216px] lg:p-[26px] lg:rounded-[30px] text-left bg-white">
          <div className="relative flex flex-col">
            <div className="2xs:mt-[16px] xs:mt-[16px] sm:mt-[16px] 2xs:text-[12px] xs:text-[14px] sm:text-[14px] tb:text-[18px] tb:w-full tb:min-w-[336px] tb:max-h-28 lg:text-[18px] lg:w-full lg:min-w-[336px] lg:max-h-28 flex-shrink-0 leading-[150%]">
              <p className="font-bold text-[#3F29C1]">
                입대를 앞둔 커플이라면 꼭 써봤으면 합니다.
              </p>
              <p>
                실제 자대에 와서 서로 다른 환경을 이해하는데 도움을 받기도
                했네요.
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="2xs:mt-[24px] xs:mt-[54px] sm:mt-[54px] tb:mt-[60px] lg:mt-[60px] text-[14px]  2xs:text-[11.6px] sm:text-[14px] tb:text-[18px] lg:text-[18px] text-[#888790] flex flex-row items-center gap-2">
              <p>한**</p>
              <p>
                <img
                  src={circle}
                  className="2xs:w-[3px] 2xs:h-[3px] xs:w-[3px] xs:h-[3px] sm:w-[3px] sm:h-[3px]"
                  alt=""
                />
              </p>
              <p>3개월 차 군화</p>
            </div>
            <div className="flex items-end">
              <img
                className="2xs:w-10 2xs:h-8 xs:w-12 xs:h-10 sm:w-12 sm:h-10 tb:w-[36px] tb:h-[48px] lg:w-[36px] lg:h-[48px]"
                src={profile2}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="profile3 flex flex-col w-full h-[168px] 2xs:px-3 xs:px-4 sm:px-4 py-1 rounded-2xl 2xs:h-[126px] tb:w-[400px] tb:h-[216px] tb:p-[26px] tb:rounded-[30px] lg:w-[378px] lg:h-[216px] lg:p-[26px] lg:rounded-[30px] text-left bg-white">
          <div className="relative flex flex-col">
            <div className="2xs:mt-[16px] xs:mt-[16px] sm:mt-[16px] 2xs:text-[12px] xs:text-[14px] sm:text-[14px] tb:text-[18px] tb:w-full tb:min-w-[330px] tb:max-h-28 lg:text-[18px] lg:w-full lg:min-w-[330px] lg:max-h-28 flex-shrink-0 leading-[150%]">
              <p className="font-bold text-[#3F29C1]">
                가장 힘든 시간을 예쁘게 남길 수 있었어요.
              </p>
              <p>
                제일 애틋했던 시간이 선명하게 남아있어서 종종 들어와 추억들을
                열어보기 좋아요.
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="2xs:mt-[24px] xs:mt-[54px] sm:mt-[54px] tb:mt-[60px] lg:mt-[60px] text-[14px]  2xs:text-[11.6px] sm:text-[14px] tb:text-[18px] lg:text-[18px] text-[#888790] flex flex-row items-center gap-2">
              <p>강**</p>
              <p>
                <img
                  src={circle}
                  className="2xs:w-[3px] 2xs:h-[3px] xs:w-[3px] xs:h-[3px] sm:w-[3px] sm:h-[3px]"
                  alt=""
                />
              </p>
              <p>7개월 차 곰신</p>
            </div>
            <div className="flex items-end">
              <img
                className="2xs:w-10 2xs:h-8 xs:w-12 xs:h-10 sm:w-12 sm:h-10 tb:w-[36px] tb:h-[48px] lg:w-[36px] lg:h-[48px]"
                src={profile3}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Case;
