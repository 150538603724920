import React from 'react';
import './index.css';
import btn from '../../assets/images/contact-btn.svg';
const shareOrPrompt = () => {
  if (navigator.share) {
    navigator
      .share({
        title: document.title,
        url: window.location.href,
      })
      .catch(error => {
        console.error('Error sharing:', error);
        prompt('아래 URL을 복사해주세요 :', window.location.href);
      });
  } else {
    prompt('아래 URL을 복사해주세요 :', window.location.href);
  }
};

const Contact = () => {
  return (
    <section className="w-full h-full bg-white">
      <div className="w-full h-full 2xs:px-[24px] 2xs:py-10 xs:px-[24px] sm:px-[26px] py-12 tb:py-16 lg:py-16 bg-[#001640]">
        <div className="text-center text-white">
          <p className="2xs:hidden xs:text-[20px] sm:text-[22.8px] tb:text-[32px] lg:text-[32px] font-extrabold">
            불안한 기다림을 설레는 기대감으로
            <br />
            꽃신을 위한 그 첫번째 준비단계
          </p>
          <p className="2xs:text-[20px] xs:hidden sm:hidden tb:hidden lg:hidden font-extrabold">
            불안한 기다림을
            <br />
            설레는 기대감으로
            <br />
            꽃신을 위한
            <br />그 첫번째 준비단계
          </p>
          <p className="2xs:hidden xs:text-[12.4px] sm:text-[13px] tb:text-[18px] lg:text-[18px] mt-[16px]">
            다신 돌아오지 않을 시간을 우리만의 추억으로 만들어볼까요?
          </p>
          <p className="2xs:text-[11.6px] xs:hidden sm:hidden tb:hidden lg:hidden 2xs:mt-[24px] mt-[32px]">
            다신 돌아오지 않을 시간을
            <br />
            우리만의 추억으로 만들어볼까요?
          </p>
        </div>
        <div className="relative flex flex-col lg:flex-row 2xs:mt-[40px] mt-[64px] lg:mt-[72px] gap-6 lg:gap-8 sm:px-2 tb:px-[120px] lg:px-[120px] tb:items-center justify-center">
          <a href="https://www.dalchetong.com" className="no-underline">
            <div className="contact tb:w-[464px] tb:h-[180px] lg:min-w-[576px] lg:h-[180px] flex flex-col 2xs:p-[18px] xs:p-[26px] sm:p-[24px] tb:p-[26px] lg:p-[26px] bg-[#6952F4] rounded-2xl tb:rounded-[28px] lg:rounded-[32px] text-white flex-shrink-0 cursor-pointer">
              <div className="flex flex-col">
                <p className="text-[12px] tb:text-[16px] lg:text-[16px]">
                  예비군화라면,
                </p>
                <p className="2xs:text-[13.6px] xs:text-[18px] sm:text-[17.2px] tb:text-[24px] lg:text-[24px] font-bold">
                  달의 우체통으로 바로가기
                </p>
              </div>
              <div className="flex flex-row justify-between items-center 2xs:mt-[24px] xs:mt-[28px] sm:mt-[38px] tb:mt-[48px] lg:mt-[48px]">
                <p className="2xs:text-[10px] xs:text-[12px] sm:text-[12px] tb:text-[16px] lg:text-[16px]">
                  지금 바로 달의 우체통을 준비해요.
                </p>
                <img
                  className="2xs:w-4 xs:w-6 sm:w-6 tb:w-8 lg:w-8 xs:h-3 sm:h-3 tb:h-3 lg:h-3"
                  src={btn}
                  alt=""
                />
              </div>
            </div>
          </a>
          <div
            onClick={shareOrPrompt}
            className="contact tb:w-[464px] tb:h-[180px] lg:min-w-[576px] lg:h-[180px] flex flex-col 2xs:p-[18px] xs:p-[26px] sm:p-[24px] tb:p-[26px] lg:p-[26px] bg-[#EF5BC5] rounded-2xl tb:rounded-[28px] lg:rounded-[32px] text-white flex-shrink-0 cursor-pointer"
          >
            <div className="flex flex-col">
              <p className="text-[12px] tb:text-[16px] lg:text-[16px]">
                예비곰신이라면,
              </p>
              <p className="2xs:text-[13.6px] xs:text-[18px] sm:text-[17.2px] tb:text-[24px] lg:text-[24px] font-bold">
                상대에게 링크로 공유하기
              </p>
            </div>
            <div className="flex flex-row justify-between items-center 2xs:mt-[24px] xs:mt-[28px] sm:mt-[38px] tb:mt-[48px] lg:mt-[48px]">
              <p className="2xs:text-[10px] xs:text-[12px] sm:text-[12px] tb:text-[16px] lg:text-[16px]">
                함께 달의 우체통을 써보자고 제안해요.
              </p>
              <img
                className="2xs:w-4 xs:w-6 sm:w-6 tb:w-8 lg:w-8 xs:h-3 sm:h-3 tb:h-3 lg:h-3"
                src={btn}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
