import React from 'react';
import './index.css';
import logo from '../../assets/images/logo.svg';

const Head = () => {
  return (
    <section className="w-full h-full 2xs:bg-headMoonBg xs:bg-headMoonBg sm:bg-headMoonBg md:bg-headMoonBg tb:bg-headMailboxBg lg:bg-headMailboxBg xl:bg-headMailboxBg bg-no-repeat bg-cover 2xs:px-[24px] 2xs:pb-[24px] xs:px-[24px] xs:pb-[40px] sm:px-[24px] sm:pb-[40px] md:px-[160px] tb:px-[180px] tb:pb-[48px] lg:px-[240px] lg:pb-[48px] xl:px-[280px] xl:pb-[80px]">
      <header className="w-full h-[70px] md:h-24 tb:h-20 lg:h-20 flex items-center">
        <img
          className="relative flex 2xs:w-28 xs:w-24 sm:w-28 md:w-40 md:h-11 tb:w-36 lg:w-36 h-9"
          src={logo}
          alt=""
        />
      </header>
      <div className="relative 2xs:flex 2xs:flex-col 2xs:mt-[12px] xs:flex xs:flex-col xs:mt-[68px] sm:flex sm:flex-col sm:mt-[68px] md:flex-row tb:flex-row lg:flex-row md:justify-between tb:justify-between lg:justify-between">
        <div className="text-section relative flex">
          <div className="relative flex flex-col pb-10 md:py-20 tb:py-0 lg:py-0 text-left">
            <div className="2xs:text-[20px] xs:text-[24px] sm:text-[24px] md:text-[42px] tb:text-[42px] lg:text-[42px] font-extrabold leading-[150%]">
              <p className="title">
                입대를 앞둔 연인이라면
                <br />
                가장 먼저 준비해야 할
              </p>
              <p className="text-white">함께하는 훈련소 예약편지</p>
            </div>
            <div className="2xs:hidden xs:text-[12px] sm:text-[12px] md:text-[20px] tb:text-[20px] lg:text-[20px] text-white leading-[150%] mt-[16px] font-normal">
              힘든 시간마저 같이 걸어가기로 마음먹었다면,
              <br />
              두려움보다 다시 만날 날에 대한 설렘을 선물하는 건 어떨까요?
            </div>
            <div className="2xs:text-[10px] xs:hidden sm:hidden md:hidden tb:hidden lg:hidden text-white leading-[150%] mt-[20px] font-normal">
              힘든 시간마저 같이 걸어가기로 마음먹었다면,
              <br />
              두려움보다 다시 만날 날에 대한 설렘을
              <br />
              선물하는 건 어떨까요?
            </div>
            <div className="2xs:mt-[18px] mt-[32px] md:mt-[64px] tb:mt-[64px] lg:mt-[64px]">
              <a href="https://www.dalchetong.com" className="no-underline">
                <button className="2xs:w-24 2xs:h-8 2xs:rounded-[32px] xs:w-28 xs:h-10 xs:rounded-[24px] sm:w-28 sm:h-10 sm:rounded-[24px] 2xs:text-[10px] xs:text-[12px] sm:text-[12px] md:w-[184px] md:h-[64px] md:text-[18px] md:rounded-[88px] tb:w-[184px] tb:h-[64px] tb:text-[18px] tb:rounded-[88px] lg:w-[184px] lg:h-[64px] lg:text-[18px] lg:rounded-[88px] bg-[#6349FF] text-white font-bold">
                  바로 시작하기
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Head;
