import React from 'react';
import './index.css';
import arrow from '../../assets/images/how-arrow-icon.svg';
import how1 from '../../assets/images/how-icon-1.svg';
import how2 from '../../assets/images/how-icon-2.svg';
import how3 from '../../assets/images/how-icon-3.svg';
import how4 from '../../assets/images/how-icon-4.svg';

const How = () => {
  return (
    <section className="w-full h-full 2xs:px-[24px] 2xs:py-10 xs:px-[24px] xs:py-16 sm:px-[22px] sm:py-16 tb:px-[64px] tb:py-[100px] lg:px-[12px] lg:py-[100px] xl:px-[280px] bg-[#D6E1F2] text-center">
      <div>
        <p className="2xs:hidden xs:hidden sm:hidden tb:text-[32px] lg:text-[32px] font-bold">
          달의 우체통은 이렇게 작동해요
        </p>
        <p className="2xs:text-[20px] xs:text-[22px] sm:text-[22px] tb:hidden lg:hidden font-bold">
          달의 우체통은
          <br />
          이렇게 작동해요
        </p>
        <a
          href="https://misae.notion.site/0b20049052994693bfa3841a62a235e4"
          className="inline flex-row"
        >
          <p className="flex flex-row 2xs:text-[11.6px] xs:text-[13px] sm:text-[13px] tb:text-[18px] lg:text-[18px] 2xs:mt-[12px] xs:mt-[12px] sm:mt-[14px] tb:mt-[10px] lg:mt-[10px] justify-center underline decoration-1 text-de">
            자세한 사용법이 궁금하다면
            <p>
              <img
                src={arrow}
                className="2xs:w-4 2xs:h-4 xs:w-4 xs:h-4 sm:w-5 sm:h-5"
                alt=""
              />
            </p>
          </p>
        </a>
      </div>
      <div className="relative 2xs:px-4 xs:px-4 2xs:mt-[32px] xs:mt-[48px] sm:mt-[64px] tb:mt-[72px] lg:mt-[72px] grid grid-cols-1 sm:grid-cols-2 tb:grid-cols-2 lg:grid-cols-4 2xs:gap-4 xs:gap-4 sm:gap-3 tb:gap-4 lg:gap-2 tb:flex tb:flex-col tb:items-center tb:justify-center justify-around">
        <div className="how1 flex flex-col justify-between w-full 2xs:h-[164px] h-[180px] p-[14px] rounded-2xl tb:w-[472px] tb:h-[288px] tb:p-[24px] tb:rounded-[30px] lg:w-[272px] lg:h-[314px] lg:p-[24px] lg:rounded-[30px] flex-shrink-0 text-left bg-white">
          <div className="flex flex-row justify-between">
            <div className="2xs:text-[12px] xs:text-[12px] sm:text-[12.8px] tb:text-[18px] lg:text-[18px] font-bold text-[#B2C9EF]">
              <p>STEP 1</p>
            </div>
            <div>
              <button className="2xs:w-[56px] 2xs:h-6 2xs:text-[10px] xs:w-[56px] xs:h-6 xs:text-[10px] sm:w-[64px] sm:h-6 sm:text-[10.8px] tb:w-[100px] tb:h-[44px] tb:text-[16px] lg:w-[100px] lg:h-[44px] lg:text-[16px] text-white font-bold bg-[#6952F4] rounded-[24px]">
                예비군화
              </button>
            </div>
          </div>
          <div className="relative flex flex-col">
            <div>
              <img
                src={how1}
                className="2xs:w-7 2xs:h-7 xs:w-7 xs:h-7 sm:w-8 sm:h-8 tb:w-[50px] tb:h-[50px] lg:w-[50px] lg:h-[50px]"
                alt=""
              />
            </div>
            <div className="2xs:mt-[10px] xs:mt-[10px] sm:mt-[14px] tb:mt-[20px] tb:w-full tb:min-w-[326px] tb:max-h-28 lg:mt-[20px] lg:w-full lg:min-w-[326px] lg:max-h-28 flex-shrink-0 lg:leading-[150%]">
              <p className="2xs:text-[12px] xs:text-[12px] sm:text-[13.2px] tb:text-[20px] lg:text-[20px] font-bold">
                우체통 만들기
              </p>
              <p className="2xs:mt-1 xs:mt-1 tb:mt-[8px] lg:mt-[8px] sm:mt-[8px] 2xs:text-[11.2px] xs:text-[12px] sm:text-[12px] tb:text-[16.8px] lg:text-[16.8px]">
                기간과 시간 등을 설정하여
                <br />
                우체통을 만들어요.
              </p>
            </div>
          </div>
        </div>
        <div className="how2 flex flex-col justify-between w-full 2xs:h-[164px] h-[180px] p-[14px] rounded-2xl tb:w-[472px] tb:h-[288px] tb:p-[24px] tb:rounded-[30px] lg:w-[272px] lg:h-[314px] lg:p-[24px] lg:rounded-[30px] flex-shrink-0 text-left bg-white">
          <div className="flex flex-row justify-between">
            <div className="2xs:text-[12px] xs:text-[12px] sm:text-[12.8px] tb:text-[18px] lg:text-[18px] font-bold text-[#B2C9EF]">
              <p>STEP 2</p>
            </div>
            <div>
              <button className="2xs:w-[56px] 2xs:h-6 2xs:text-[10px] xs:w-[56px] xs:h-6 xs:text-[10px] sm:w-[64px] sm:h-6 sm:text-[10.8px] tb:w-[100px] tb:h-[44px] tb:text-[16px] lg:w-[100px] lg:h-[44px] lg:text-[16px] text-white font-bold bg-[#6952F4] rounded-[24px]">
                예비군화
              </button>
            </div>
          </div>
          <div className="relative flex flex-col">
            <div>
              <img
                src={how2}
                className="2xs:w-7 2xs:h-7 xs:w-7 xs:h-7 sm:w-8 sm:h-8 tb:w-[50px] tb:h-[50px] lg:w-[50px] lg:h-[50px]"
                alt=""
              />
            </div>
            <div className="2xs:mt-[10px] xs:mt-[10px] sm:mt-[14px] tb:mt-[20px] tb:w-full tb:min-w-[326px] tb:max-h-28 lg:mt-[20px] lg:w-full lg:min-w-[326px] lg:max-h-28 flex-shrink-0 lg:leading-[150%]">
              <p className="2xs:text-[12px] xs:text-[12px] sm:text-[13.2px] tb:text[20px] lg:text-[20px] font-bold">
                편지쓰기
              </p>
              <p className="2xs:mt-1 xs:mt-1 tb:mt-[8px] lg:mt-[8px] sm:mt-[8px] 2xs:text-[11.2px] xs:text-[12px]  sm:text-[12px] tb:text-[16.8px] lg:text-[16.8px]">
                입소 전, 미리 편지를 작성해요.
                <br />
                사진과 음성도 넣을 수 있어요.
              </p>
            </div>
          </div>
        </div>
        <div className="how3 flex flex-col justify-between w-full 2xs:h-[164px] h-[180px] p-[14px] rounded-2xl tb:w-[472px] tb:h-[288px] tb:p-[24px] tb:rounded-[30px] lg:w-[272px] lg:h-[314px] lg:p-[24px] lg:rounded-[30px] flex-shrink-0 text-left bg-white">
          <div className="flex flex-row justify-between">
            <div className="2xs:text-[12px] xs:text-[12px] sm:text-[12.8px] tb:text-[18px] lg:text-[18px] font-bold text-[#B2C9EF]">
              <p>STEP 3</p>
            </div>
            <div>
              <button className="2xs:w-[88px] 2xs:h-[24px] 2xs:text-[10px] xs:w-[88px] xs:h-7 xs:text-[10px] sm:w-[88px] sm:h-6 sm:text-[10.8px] tb:min-w-[148px] tb:h-[44px] tb:text-[16px]  lg:min-w-[148px] lg:h-[44px] lg:text-[16px] flex-shrink-0 text-white font-bold bg-[#6952F4] rounded-[24px]">
                예비군화 → 곰신
              </button>
            </div>
          </div>
          <div className="relative flex flex-col">
            <div>
              <img
                src={how3}
                className="2xs:w-7 2xs:h-7 xs:w-7 xs:h-7 sm:w-8 sm:h-8 tb:w-[50px] tb:h-[50px] lg:w-[50px] lg:h-[50px]"
                alt=""
              />
            </div>
            <div className="2xs:mt-[10px] xs:mt-[10px] sm:mt-[14px] tb:mt-[20px] lg:mt-[20px] tb:w-full tb:min-w-[326px] tb:max-h-28 lg:w-full lg:min-w-[326px] lg:max-h-28 flex-shrink-0 lg:leading-[150%]">
              <p className="2xs:text-[12px] xs:text-[12px] sm:text-[13.2px] lg:text-[20px] font-bold">
                링크 전달하기
              </p>
              <p className="2xs:mt-1 xs:mt-1 tb:mt-[8px] lg:mt-[8px] sm:mt-[8px] 2xs:text-[11.2px] xs:text-[12px]  sm:text-[12px] tb:text-[16.8px] lg:text-[16.8px]">
                입소 전, 상대방에게
                <br />
                우체통 링크를 전달해요.
              </p>
            </div>
          </div>
        </div>
        <div className="how4 flex flex-col justify-between w-full 2xs:h-[164px] h-[180px] p-[14px] rounded-2xl tb:w-[472px] tb:h-[288px] tb:p-[24px] tb:rounded-[30px] lg:w-[272px] lg:h-[314px] lg:p-[24px] lg:rounded-[30px] flex-shrink-0 text-left bg-white">
          <div className="flex flex-row justify-between">
            <div className="2xs:text-[12px] xs:text-[12px] sm:text-[12.8px] tb:text-[18px] lg:text-[18px] font-bold text-[#B2C9EF]">
              <p>STEP 4</p>
            </div>
            <div>
              <button className="2xs:w-[38px] 2xs:h-6 2xs:text-[10px] xs:w-[40px] xs:h-6 xs:text-[10px] sm:w-[36px] sm:h-6 sm:text-[10.8px] tb:w-[64px] tb:h-[44px] tb:text-[16px] lg:w-[64px] lg:h-[44px] lg:text-[16px] text-white font-bold bg-[#EF5BC6] rounded-[24px]">
                곰신
              </button>
            </div>
          </div>
          <div className="relative flex flex-col">
            <div>
              <img
                src={how4}
                className="2xs:w-7 2xs:h-7 xs:w-7 xs:h-7 sm:w-8 sm:h-8 tb:w-[50px] tb:h-[50px] lg:w-[50px] lg:h-[50px]"
                alt=""
              />
            </div>
            <div className="2xs:mt-[10px] xs:mt-[10px] sm:mt-[14px] tb:mt-[20px] tb:w-full tb:min-w-[326px] tb:max-h-28 lg:mt-[20px] lg:w-full lg:min-w-[326px] lg:max-h-28 flex-shrink-0 lg:leading-[150%]">
              <p className="2xs:text-[12px] xs:text-[12px] sm:text-[13.2px] tb:text-[20px] lg:text-[20px] font-bold">
                답장 남기기
              </p>
              <p className="2xs:mt-1 xs:mt-1 tb:mt-[8px] lg:mt-[8px] sm:mt-[8px] 2xs:text-[11.2px] xs:text-[12px]  sm:text-[12px] tb:text-[16.8px] lg:text-[16.8px]">
                상대가 준비한 편지를 받으며
                <br />
                답장을 남길 수 있어요.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default How;
