import React from 'react';
import logo from '../../assets/images/logo-black.svg';
import insta from '../../assets/images/insta-icon.svg';

const Footer = () => {
  return (
    <>
      <footer className="w-full 2xs:hidden xs:hidden sm:hidden tb:h-[264px] lg:h-[264px] bg-[#F5F7FA] text-black tb:text-[14px] lg:text-[14px] tb:px-[216px] lg:px-[216px] py-[64px] tb:py-[36px] lg:py-[36px] xl:px-[260px] flex tb:flex-row lg:flex-row tb:justify-between lg:justify-between">
        <li className="absolute right-[212px] xl:right-[264px] justify-start items-start list-none">
          <a
            href="https://www.instagram.com/dalchetong_official"
            className="no-decoration"
          >
            <img
              className="relative flex tb:w-[54px] lg:w-[54px] h-[54px]"
              src={insta}
              alt=""
            />
          </a>
        </li>
        <div className="relative tb:flex-col lg:flex-col">
          <ul className="flex tb:flex-col lg:flex-col tb:gap-4 lg:gap-4">
            <li>
              <img
                className="relative flex tb:min-w-36 lg:min-w-36 h-9 flex-shrink-0"
                src={logo}
                alt=""
              />
            </li>
            <li className="flex-row ml-1">
              <p>
                {' '}
                <span className="font-bold mr-3">문의</span>
                help@dalchetong.com
              </p>
            </li>
            <li className="mt-3 ml-1">
              <p>
                모든 문의 사항은 달의 우체통 공식 인스타그램 DM 혹은 <br />
                달의 우체통 공식 이메일을 통해 접수해주세요.
              </p>
            </li>
          </ul>
        </div>
        <div className="flex flex-col justify-end">
          <ul className="flex tb:flex-col lg:flex-col 2xs:gap-2 tb:gap-[84px] lg:gap-[84px]">
            <li className="flex-col tb:pt-[10px] lg:pt-[10px] text-[#848484] font-normal">
              <ul className="flex flex-row gap-1 tb:gap-4 tb:mt-3 lg:gap-4 lg:mt-3  justify-end items-end">
                <li>
                  <a
                    className="no-decoration"
                    href="https://misae.notion.site/539191bc947f49e494787e50f0d5a5ae?pvs=4"
                  >
                    이용약관
                  </a>
                </li>
                <li>|</li>
                <li>
                  <a
                    className="no-decoration"
                    href="https://misae.notion.site/71b33659a4454e0a9e4cd1955d0efbce?pvs=4"
                  >
                    개인정보 처리방침
                  </a>
                </li>
              </ul>
              <p className="pt-[10px]">Copyright ⓒMISAE. All rights Reserved</p>
            </li>
          </ul>
        </div>
      </footer>
      <footer className="tb:hidden lg:hidden w-full h-[264px] 2xs:text-[9.6px] xs:text-[11px] sm:text-[11.6px] bg-[#F5F7FA] flex flex-col 2xs:px-[20px] xs:px-[24px] sm:px-[24px] py-[30px]">
        <div className="flex flex-row justify-between">
          <div className="flex items-start">
            <div>
              <a
                href="https://www.instagram.com/dalchetong_official"
                className="no-decoration"
              >
                <img
                  className="absolute right-6 flex lg:w-[32px] h-[28px]"
                  src={insta}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col items-start">
          <img
            className="relative flex tb:min-w-[108px] lg:min-w-[108px] h-[28px] flex-shrink-0 items-start justify-start"
            src={logo}
            alt=""
          />
          <div className="flex-row mt-[26px] ml-1 text-[#212121] font-normal">
            <p>
              {' '}
              <span className="font-bold mr-2">문의</span>
              help@dalchetong.com
            </p>
          </div>
          <div className="mt-3 ml-1 text-[#414141]">
            <p>
              모든 문의 사항은 달의 우체통 공식 인스타그램 DM 혹은 <br />
              달의 우체통 공식 이메일을 통해 접수해주세요.
            </p>
          </div>
          <div className="flex-col ml-1 pt-[40px]  text-[#848484]">
            <ul className="flex flex-row gap-1 justify-starts items-start">
              <li>
                <a
                  className="no-decoration"
                  href="https://misae.notion.site/539191bc947f49e494787e50f0d5a5ae?pvs=4"
                >
                  이용약관
                </a>
              </li>
              <li>|</li>
              <li>
                <a
                  className="no-decoration"
                  href="https://misae.notion.site/71b33659a4454e0a9e4cd1955d0efbce?pvs=4"
                >
                  개인정보 처리방침
                </a>
              </li>
            </ul>
            <p className="pt-[6px]">Copyright ⓒMISAE. All rights Reserved</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
