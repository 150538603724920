import React from 'react';
import line from '../../assets/images/line-icon.svg';
import content1 from '../../assets/images/content-img-1.png';
import content2 from '../../assets/images/content-img-2.png';
import content3 from '../../assets/images/content-img-3.png';

const Content = () => {
  return (
    <section className="w-full h-full flex flex-col 2xs:px-[10px] 2xs:py-10 xs:px-[24px] sm:px-[24px] tb:px-[140px] lg:px-[188px] xl:px-[280px] py-16 bg-white text-center">
      <div className="flex flex-col gap-7 tb:py-[100px] lg:py-[100px] items-center text-center justify-center">
        <p className="2xs:text-[18px] xs:text-[20px] sm:text-[20px] tb:text-[32px] lg:text-[32px] font-bold">
          훈련소 예약문자를 고민한다면
          <br />
          <span className="text-[#959498] line-through">메신저 말고</span> 달의
          우체통
        </p>
        <p>
          <img
            src={line}
            className="2xs:w-8 2xs:h-4 xs:mb-[64px] sm:mb-[64px]"
            alt=""
          />
        </p>
      </div>
      <div className="content1 relative px-[28px] 2xs:px-[0px] xs:px-[0px] sm:px-[0px] py-10 tb:p-16 lg:p-16 flex flex-col lg:flex-row gap-0 tb:gap-10 lg:gap-10 tb:text-left lg:text-left">
        <div className="lg:w-1/2 flex flex-col">
          <p className="2xs:text-[18px] xs:text-[20px] sm:text-[24px] tb:text-[32px] lg:text-[32px] font-bold">
            매일 새로운
            <br />
            편지 주제 추천
          </p>
          <p className="2xs:text-[11.6px] xs:text-[14px] sm:text-[14px] tb:text-[18px] lg:text-[18px] font-normal 2xs:mt-[10px] xs:mt-[10px] sm:mt-[10px] tb:mt-[20px] lg:mt-[20px] leading-[150%]">
            곰신의 심리를 완벽히 파악한 꿀팁부터
            <br />
            자대에 가기 전 반드시 대화해야 할 주제까지
            <br />
            매일 다른 부담없고 재미있는 주제가 추천돼요.
          </p>
        </div>
        <div className="mt-[46px]">
          <img
            className="h-full 2xs:w-[248px] xs:w-[312px] sm:w-[380px] tb:w-[400px] lg:w-[612px]"
            src={content1}
            alt=""
          />
        </div>
      </div>
      <div className="content2 relative py-10 tb:p-16 lg:p-16 flex flex-col lg:flex-row gap-0 tb:gap-16 lg:gap-16 tb:text-right lg:text-right">
        <div className="2xs:hidden xs:hidden sm:hidden tb:hidden">
          <img className="h-full lg:w-[540px]" src={content2} alt="" />
        </div>
        <div className="lg:w-1/2 flex flex-col">
          <p className="2xs:text-[18px] xs:text-[20px] sm:text-[24px] tb:text-[32px] lg:text-[32px] font-bold">
            허전한 대화방보단
            <br />
            설렘 가득한 기다림을
          </p>
          <p className="2xs:text-[11.6px] xs:text-[14px] sm:text-[14px] tb:text-[18px] lg:text-[18px] font-normal 2xs:mt-[10px] xs:mt-[10px] sm:mt-[10px] tb:mt-[20px] lg:mt-[20px] leading-[150%]">
            답이 돌아오지 않는 채팅방에서 받는 예약문자는
            <br />
            되려 쓸쓸하게 느껴져요. 달의 우체통에서는
            <br />
            생생한 편지 배달 시간을 알려주고,
            <br />
            기다림에 설렘을 불어 넣어줘요.
          </p>
        </div>
        <div className="lg:hidden mt-[46px]">
          <img
            className="h-full 2xs:w-[248px] xs:w-[312px] sm:w-[380px]  tb:w-[400px] lg:w-[580px]"
            src={content2}
            alt=""
          />
        </div>
      </div>
      <div className="content3 relative py-10 tb:p-16 lg:p-16 flex flex-col lg:flex-row gap-0 tb:gap-10 lg:gap-10 tb:text-right lg:text-left">
        <div className="lg:w-1/2 flex flex-col">
          <p className="2xs:text-[18px] xs:text-[20px] sm:text-[24px] tb:text-[32px] lg:text-[32px] font-bold">
            접속 여부 관계없이
            <br />
            사라지지 않는 답장
          </p>
          <p className="2xs:text-[11.6px] xs:text-[14px] sm:text-[14px] tb:text-[18px] lg:text-[18px] font-normal 2xs:mt-[10px] xs:mt-[10px] sm:mt-[10px] tb:mt-[20px] lg:mt-[20px] leading-[150%]">
            <span className="font-bold">
              3일 간 접속하지 않으면 메시지가 삭제되는
              <br />
              메신저와는 달리,
            </span>{' '}
            달의 우체통에서 남긴 답장은
            <br />
            즉시 저장되어 사라지지 않고 언제든지 확인 가능해요.
          </p>
        </div>
        <div className="mt-[46px]">
          <img
            className="h-full 2xs:w-[248px] xs:w-[312px] sm:w-[380px]  tb:w-[400px] lg:w-[560px]"
            src={content3}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Content;
