import React from 'react';
import webBg from '../../assets/images/memory-web-bg.png';
import mobileBg from '../../assets/images/memory-mobile-bg.png';

const Memory = () => {
  return (
    <section className="w-full h-full relative 2xs:px-[24px] 2xs:py-10 xs:px-[20px] xs:py-16 sm:px-[28px] sm:py-16 tb:px-[120px] tb:py-[100px] lg:px-[120px] lg:py-[100px] bg-[#5A79EA] text-white text-center items-center justify-center">
      <div>
        <p className="2xs:text-[20px] xs:text-[20px] sm:text-[22px] tb:text-[32px] lg:text-[32px] font-bold">
          언제든지 꺼내볼 수 있는
          <br />
          우리만의 소중한 추억
        </p>
        <p className="2xs:text-[8.8px] xs:text-[12.4px] sm:text-[13px] tb:text-[18px] lg:text-[18px] 2xs:mt-[10px] xs:mt-[10px] sm:mt-[12px] tb:mt-[18px] lg:mt-[18px]">
          시간이 지나도 달의 우체통에 남겨진 기록들은 남아있어요.
        </p>
      </div>
      <div className="2xs:hidden xs:hidden sm:hidden  tb:w-[636px] tb:h-[326px] tb:mt-[44px] lg:w-[836px] lg:h-[326px] lg:mt-[44px] mb-[12px] m-auto rounded-[28px]">
        <div className="memory-container relative overflow-hidden w-full h-full p-8 flex flex-row rounded-[28px]">
          <img
            src={webBg}
            className="absolute inset-0 w-full h-full object-cover z-10"
            alt=""
          />
          <div className="flex flex-row justify-between tb:gap-[20px] lg:gap-[152px] z-20">
            <div className="flex flex-col">
              <p className="text-left tb:text-[28px] lg:text-[32px] font-bold">
                우리만의 달의 우체통은
                <br />
                실물로도 간직할 수 있어요
              </p>
              <button className="tb:w-[148px] tb:h-[52px] tb:text-[18px] lg:w-[148px] lg:h-[52px] lg:text-[18px] mt-4 font-bold flex-shrink-0 text-[#2D5AFA] bg-white rounded-[28px]">
                <a href="https://misae.notion.site/894c1693da0c4639bc6efe1b87df5f06">
                  구경하러 가기
                </a>
              </button>
            </div>
            <div className="flex flex-col justify-between tb:text-[14px]">
              <p>*현재 상품 제작은 준비 중에 있습니다.</p>

              <p className="font-bold">
                편지북, 우체통 틴케이스 및 편지 굿즈 등{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="tb:hidden lg:hidden 2xs:w-full 2xs:h-[172px] 2xs:mt-[30px] xs:w-full xs:h-[216px] xs:mt-[30px] sm:w-full sm:h-[228px] sm:mt-[32px] mb-[12px] m-auto items-center justify-center text-left">
        <div className="memory-container relative overflow-hidden w-full h-full 2xs:p-5 xs:p-6 sm:p-7 flex flex-row 2xs:rounded-[20px] rounded-[28px]">
          <img
            src={mobileBg}
            className="absolute inset-0 w-full h-full object-cover z-10"
            alt=""
          />
          <div className="z-20">
            <p className="2xs:text-[14px] xs:text-[18px] sm:text-[18px] font-bold">
              우리만의 달의 우체통은
              <br />
              실물로도 간직할 수 있어요
            </p>
            <p className="2xs:text-[10px] text-[11px] 2xs:mt-1 xs:mt-2 sm:mt-2 font-bold">
              편지북, 우체통 틴케이스 및 편지 굿즈 등{' '}
            </p>
            <div className="flex flex-row justify-between">
              <div className="2xs:mt-[64px] xs:mt-[76px] sm:mt-[88px] flex 2xs:flex-col xs:flex-row sm:flex-row 2xs:items-start items-end gap-1 xs:gap-1 sm:gap-7">
                <p className="2xs:text-[8px] text-[10px]">
                  *현재 상품 제작은 준비 중에 있습니다.
                </p>
                <div className="absolute 2xs:right-4 2xs:bottom-3 xs:right-6 xs:bottom-5 sm:right-7 sm:bottom-4 items-end">
                  <button className="2xs:min-w-[60px] 2xs:min-h-[22px] 2xs:text-[8px] xs:min-w-[88px] xs:min-h-[28px] xs:text-[12px] sm:min-w-[100px] sm:min-h-[32px] sm:text-[13.2px] font-bold flex-shrink-0 text-[#2D5AFA] bg-white rounded-[24px]">
                    <a href="https://misae.notion.site/894c1693da0c4639bc6efe1b87df5f06">
                      구경하러 가기{' '}
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Memory;
