import React from 'react';
import Head from '../../components/Head/Head';
import Case from '../../components/Case/Case';
import Content from '../../components/Content/Content';
import How from '../../components/How/How';
import Memory from '../../components/Memory/Memory';
import Contact from '../../components/Contact/Contact';
import Footer from '../../components/Footer/Footer';

const Main = () => {
  return (
    <>
      <Head />
      <Case />
      <Content />
      <How />
      <Memory />
      <Contact />
      <Footer />
    </>
  );
};

export default Main;
